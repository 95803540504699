import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate, useParams } from "react-router";
import { Box, Typography } from "@mui/material";
import { SurveyPDF } from "survey-pdf";
import PropTypes from "prop-types";
import axios from "axios";
import "react-datetime/css/react-datetime.css";

import VisitScheduleAccordion from "../TrialAccordions/VisitScheduleAccordion";
import AllBlocksAccordion from "../TrialAccordions/AllBlocksAccordion";
import SpecialVisitSession from "../Ecrf/SpecialVisitSession";
import VisitNameAccordion from "./VisitNameAccordion";
import ScheduleVisits from "./Schedule_Visits";
import TabsWithDropdown from "./TabsWithDropDown";
import getCustomNames from "../../utilities/customNames";
import { LoaderContext } from "../../context/LoaderContext";
import { __API_URL__ } from "../../SERVER_URL";
import useUser from "../../hooks/user";
import Loader from "../Loader";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function BasicTabs({
  numberOfVisits,
  setNumberOfVisits,
  selectedVisit,
  setSelectedVisit,
  AllVisitsData,
  handleSelectingVisit,
  zeroVisit,
  eventList,
  setMessage,
  setTypeOfMessage,
  trialId,
  patientId,
  setModalVisitChangeShow,
  modalVisitChangeShow,
  hasChanged,
  setHasChanged,
  patientDetailApi,
  value,
  setValue,
  allVisits,
  surveyFormBool,
  setSurveyFormBool,
  consentFormBool,
  setConsentFormBool,
  numberOfVisitsArray,
  setNumberOfVisitsArray,
  isEvent,
  selectedSessionId,
  setSelectedSessionId,
  setAddNewSurvey,
  allSurveys,
  trial,
  setScheduleBaselineModalShow,
  trialDetailApi,
  trialDetails,
  selectedTrialType,
  patientStatus,
}) {
  const { _id_ } = useParams();
  const token = localStorage.getItem("token");
  const user_data = JSON.parse(localStorage.getItem("user_data"));
  const role = user_data.role;
  const user = useUser();
  const navigate = useNavigate();

  let baseline_name = "";
  const [infoData, setInfoData] = useState({});
  const [surveyData, setSurveyData] = useState(null);
  const [fileName, setFileName] = useState("");
  const [isLoadingPdf, setIsLoadingPdf] = useState(false);
  const prevSelectedVisitIdRef = useRef(undefined);
  const loaderContext = useContext(LoaderContext);

  const defaultVisit = {
    survey_form: "",
    drug_video: false,
    extra_photos: [],
    visit_schedule: 0,
    end_visit_schedule: 0,
    visit_number: 0,
    is_all_instruction: false,
    consent_form: {
      content: "",
      title: "",
      id: "",
    },
    visit_instructions: {
      contnt: "",
      id: "",
    },
    trial_block: [],
    disabled_photos: [],
    start_date: "",
    trial_type: "",
  };

  const handleTabChange = (event, newValue, index) => {
    setSelectedSessionId(0);
    setVisitToSelect({ event: event, newValue: newValue, index: index });
    if (hasChanged) {
      setModalVisitChangeShow(true);
    } else {
      setValue(index);
      setSelectedBlock("Patient Activity in this Visit");
      setNewForm(false);

      const filteredVisit = allVisits.filter((visit) => {
        return visit.visit_number == newValue;
      });

      if (filteredVisit.length > 0) {
        setSelectedVisit({
          ...filteredVisit[0],
          start_date: new Date(filteredVisit[0].start_date + "Z"),
        });
        handleSelectingVisit(filteredVisit[0]);
        if (role === "Investigator" || role === "Inv_coordinator")
          setLocalBlocks(filteredVisit[0].trial_block);
      } else {
        if (!("visit_name" in defaultVisit))
          defaultVisit["visit_name"] = getCustomNames(newValue);

        if (newValue != 0 && newValue != -1 && newValue != -2) {
          setSelectedVisit({
            ...defaultVisit,
            visit_number: newValue,
            extra_photos: zeroVisit?.extra_photos,
            visit_instructions: zeroVisit?.visit_instructions,
          });
        } else {
          setSelectedVisit({ ...defaultVisit, visit_number: newValue });
        }

        handleSelectingVisit(defaultVisit);
      }
    }
  };

  const [consentFormOption, setConsentFormOption] = React.useState("list");
  const [surveyFormOption, setSurveyFormOption] = React.useState("list");
  const [modalShow, setModalShow] = React.useState(false);
  const [allConsents, setAllConsents] = React.useState([]);
  const [photoTypes, setPhotoTypes] = React.useState([]);
  const [isSearch, setIsSearch] = React.useState(false);
  const [disable, setDisable] = React.useState(false);
  const [localBlocks, setLocalBlocks] = React.useState([]);
  const [photoSessions, setPhotoSessions] = React.useState([]);
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [endDays, setEndDays] = useState(0);
  const [endHours, setEndHours] = useState(0);
  const [newForm, setNewForm] = React.useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [visitToSelect, setVisitToSelect] = useState({});

  useEffect(() => {
    if (isMounted && !hasChanged) {
      if (visitToSelect?.index !== undefined) {
        handleTabChange(
          visitToSelect?.event,
          visitToSelect?.newValue,
          visitToSelect?.index
        );
      } else {
        handleTabChange(0, selectedVisit?.visit_number, 0);
      }
    } else {
      setIsMounted(true);
    }
  }, [hasChanged]);

  useEffect(() => {
    if (!numberOfVisitsArray.includes(0)) {
      setNumberOfVisitsArray([0, ...numberOfVisitsArray]);
    }
  }, [numberOfVisitsArray]);

  useEffect(() => {
    const handler = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };
    if (hasChanged) {
      window.addEventListener("beforeunload", handler);

      return () => {
        window.removeEventListener("beforeunload", handler);
      };
    }
    return () => {};
  }, [hasChanged]);

  const [selectedBlock, setSelectedBlock] = React.useState(
    "Patient Activity in this Visit"
  );

  const handleDaysChange = (e) => {
    setHasChanged(true);
    const { value } = e.target;
    setDays(parseInt(value));
  };

  const handleHoursChange = (e) => {
    setHasChanged(true);
    const { value } = e.target;
    setHours(parseInt(value));
  };

  const handleDaysChangeEnd = (e) => {
    setHasChanged(true);
    const { value } = e.target;
    setEndDays(parseInt(value));
  };

  const handleHoursChangeEnd = (e) => {
    setHasChanged(true);
    const { value } = e.target;
    setEndHours(parseInt(value));
  };

  const handleInstruction = () => {
    setSelectedVisit((visit) => {
      return {
        ...visit,
        is_all_instruction: !visit.is_all_instruction,
      };
    });

    setHasChanged(true);
  };

  const handleVisitFieldChange = (e) => {
    setHasChanged(true);
    const { name, value } = e.target;

    if (name === "drug_video") {
      setSelectedVisit((visit) => {
        return {
          ...visit,
          [name]: !visit.drug_video,
        };
      });
    } else if (name === "title" || name === "content") {
      setSelectedVisit((visit) => {
        return {
          ...visit,
          consent_form: {
            ...visit.consent_form,
            [name]: value,
          },
        };
      });
    } else if (name === "contnt") {
      setSelectedVisit((visit) => {
        return {
          ...visit,
          visit_instructions: {
            ...visit.visit_instructions,
            [name]: value,
          },
        };
      });
    } else if (name === "consent_list_id") {
      setSelectedVisit((visit) => {
        return {
          ...visit,
          consent_form: {
            ...visit.consent_form,
            id: value,
          },
        };
      });
    } else if (name === "survey_form") {
      setSelectedVisit((visit) => {
        return {
          ...visit,
          survey_form: { id: value },
        };
      });
    } else if (["Lesion 1", "Lesion 2", "Lesion 3"].includes(name)) {
      setSelectedVisit((visit) => {
        let current_extras = visit.extra_photos;
        const index = current_extras.indexOf(name);
        if (index > -1) current_extras.splice(index, 1);
        else current_extras.push(name);
        return {
          ...visit,
          extra_photos: current_extras,
        };
      });
    } else {
      setSelectedVisit((visit) => {
        return {
          ...visit,
          [name]: value,
        };
      });
    }
  };

  const AllPhotosData = () => {
    let param = `trial_id=${trialId}`;

    if (patientId) {
      param = `visit_id=${selectedVisit.id}`;
    }

    loaderContext.setLoaderCount((prev) => prev + 1);

    axios
      .get(`${__API_URL__}/api/photos/visit-photo-types/web/?${param}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setPhotoTypes(res.data);
      })
      .catch((err) => {
        if (err === "Error: Request failed with status code 401") {
          user.actions.logout();
        }
      })
      .finally(() => {
        loaderContext.setLoaderCount((prev) => prev - 1);
      });
  };

  const SpecialVisitsPhotoSessions = () => {
    loaderContext.setLoaderCount((prev) => prev + 1);
    axios
      .get(
        `${__API_URL__}/api/photos/special_visit_sessions/${trialId}/?patient_id=${patientId}&visit_number=${selectedVisit?.visit_number}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((res) => {
        setPhotoSessions(res.data);
      })
      .catch((err) => {
        if (err === "Error: Request failed with status code 401") {
          user.actions.logout();
        }
      })
      .finally(() => {
        loaderContext.setLoaderCount((prev) => prev - 1);
      });
  };

  const AllConsentData = () => {
    loaderContext.setLoaderCount((prev) => prev + 1);

    axios
      .get(`${__API_URL__}/api/appiell/consent_trial/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setAllConsents(res.data);
      })
      .catch((err) => {
        if (err == "Error: Request failed with status code 401") {
          user.actions.logout();
        }
      })
      .finally(() => {
        loaderContext.setLoaderCount((prev) => prev - 1);
      });
  };

  const handleBoolOperation = (e) => {
    const targetName = e.target.name;
    if (targetName === "surveyBool") {
      if (surveyFormBool) {
        setSelectedVisit((visit) => {
          return {
            ...visit,
            survey_form: "",
          };
        });
      }
      setSurveyFormBool((prev) => !prev);
    } else if (targetName === "consentBool") {
      if (consentFormBool) {
        setSelectedVisit((visit) => {
          return {
            ...visit,
            consent_form: { id: "" },
          };
        });
      }
      setConsentFormBool((prev) => !prev);
    }
  };
  const handleSelectBlock = (block, is_new_block) => {
    if (patientId && hasChanged) {
      setModalVisitChangeShow(true);
    } else {
      setNewForm(is_new_block);
      setSelectedBlock(block);
    }
  };

  const handleVisitUpdate = () => {
    let param = "";
    if (patientId !== undefined) {
      param = `?patient_id=${patientId}`;
    }
    const sendData = {
      ...selectedVisit,
      visit_schedule: days * 24 + hours,
      end_visit_schedule: endDays * 24 + endHours,
    };
    if (!sendData.visit_name && sendData.visit_number === 0) {
      sendData.visit_name = "Baseline Visit";
    } else if (!sendData.visit_name && sendData.visit_number === -3) {
      sendData.visit_name = "Screening Visit";
    } else if (!sendData.visit_name && sendData.visit_number === -4) {
      sendData.visit_name = "Other Ecrfs";
    } else if (!sendData.visit_name && sendData.visit_number === -1) {
      sendData.visit_name = "Adverse Event";
    } else if (!sendData.visit_name && sendData.visit_number === -2) {
      sendData.visit_name = "Unscheduled Event";
    }

    loaderContext.setLoaderCount((prev) => prev + 1);

    axios
      .patch(
        `${__API_URL__}/api/photos/update_visits/${trialId}/${param}`,
        sendData,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((res) => {
        setHasChanged(false);
        AllVisitsData(false);
        setMessage("Block updated successfully");
        setTypeOfMessage("success");
        setTimeout(() => {
          document.getElementById("cusMessages").style.right = "2rem";
        }, 100);
        setTimeout(() => {
          document.getElementById("cusMessages").style.right = "-99rem";
        }, 3000);
      })
      .catch((err) => {
        if (err == "Error: Request failed with status code 401") {
          user.actions.logout();
        }
        setMessage(err.response.data.message);
        setTypeOfMessage("error");
        setTimeout(() => {
          document.getElementById("cusMessages").style.right = "2rem";
        }, 100);
        setTimeout(() => {
          document.getElementById("cusMessages").style.right = "-99rem";
        }, 3000);
      })
      .finally(() => {
        loaderContext.setLoaderCount((prev) => prev - 1);
      });
  };

  const getLocalBlocks = (queryString) => {
    axios
      .get(`${__API_URL__}/api/ecrf/get_visit_blocks/${queryString}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setLocalBlocks(res.data);
        setIsSearch(false);
      })
      .catch((err) => {
        if (err == "Error: Request failed with status code 401") {
        }
      })
      .finally(() => {});
  };

  const handleNumberOfVisitsArray = () => {
    const length = parseInt(numberOfVisits) + 1;
    let VisitNumberArray = [];
    if (numberOfVisits !== 0) {
      VisitNumberArray = Array.from({ length }, (_, index) => index);
    }
    const screeningEvent = eventList.filter((event) => event === -3);
    if (screeningEvent.length > 0) {
      const eventsWithoutScreeing = eventList.filter((event) => event !== -3);
      setNumberOfVisitsArray([
        -3,
        ...VisitNumberArray,
        ...eventsWithoutScreeing,
      ]);
    } else {
      setNumberOfVisitsArray([...VisitNumberArray, ...eventList]);
    }
  };

  const SinglePatient = () => {
    loaderContext.setLoaderCount((prev) => prev + 1);
    axios
      .get(
        `${__API_URL__}/api/patient/patient/singlepatient/?patientId=${_id_}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((res) => {
        setInfoData(res.data);
      })
      .finally(() => {
        loaderContext.setLoaderCount((prev) => prev - 1);
      });
  };

  const formModelObjects = () => {
    loaderContext.setLoaderCount((prev) => prev + 1);
    axios
      .get(
        `${__API_URL__}/api/ecrf/get_form_models/?visitId=${selectedVisit.id}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((res) => {
        // setSurveyData(res.data);
        setFileName(res.data);
      })
      .catch((err) => {
        if (err === "Error: Request failed with status code 401") {
        }
      })
      .finally(() => {
        loaderContext.setLoaderCount((prev) => prev - 1);
      });
  };
  React.useEffect(() => {
    handleNumberOfVisitsArray();
  }, [numberOfVisits, eventList]);

  // React.useEffect(() => {
  //   if (selectedBlock !== "Patient Activity in this Visit") {
  //     setNewForm(true);
  //   } else {
  //     setNewForm(false);
  //   }
  // }, [selectedBlock]);

  React.useEffect(() => {
    AllConsentData();
    AllVisitsData(true);
    if (_id_ === true) {
      SinglePatient();
    }
  }, []);

  React.useEffect(() => {
    if (selectedVisit.id) {
      AllPhotosData();
    }
  }, [selectedVisit.id, selectedTrialType]);

  const getDaysHours = () => {
    const GetDays = selectedVisit.visit_schedule / 24;
    const days = Math.floor(GetDays);
    setDays(days);
    const GetHours = selectedVisit.visit_schedule % 24;
    const hours = Math.floor(GetHours);
    setHours(hours);
  };
  React.useEffect(() => {
    getDaysHours();
  }, [selectedVisit.visit_schedule]);
  const getDaysHoursEnd = () => {
    const GetDays = selectedVisit.end_visit_schedule / 24;
    const endDays = Math.floor(GetDays);
    setEndDays(endDays);
    const GetHours = selectedVisit.end_visit_schedule % 24;
    const endHours = Math.floor(GetHours);
    setEndHours(endHours);
  };
  React.useEffect(() => {
    getDaysHoursEnd();
  }, [selectedVisit.end_visit_schedule]);

  const handleBlockChange = (block) => {
    setHasChanged(true);
    if (selectedVisit.trial_block?.some((b) => b.name === block.name)) {
      const newBlocks = selectedVisit.trial_block.filter(
        (b) => b.name !== block.name
      );
      setSelectedVisit((prev) => {
        return {
          ...prev,
          trial_block: newBlocks,
        };
      });
    } else {
      const newBlocks = [...selectedVisit.trial_block, block];
      setSelectedVisit((prev) => {
        return {
          ...prev,
          trial_block: newBlocks,
        };
      });
    }
  };

  const [searchTrailType, setSearchTrailType] = useState("");
  const [searchBlockName, setSearchBlockName] = useState("");

  useEffect(() => {
    let queryString = "?";
    if (searchTrailType) {
      queryString += `trial_type=${searchTrailType}`;
    }
    if (searchBlockName) {
      if (queryString !== "?") queryString += "&";
      queryString += `block_name=${searchBlockName}`;
    }

    getLocalBlocks(queryString);
  }, [searchTrailType, searchBlockName]);

  const handleTrialTypeChange = (event) => {
    setIsSearch(true);
    setSelectedVisit((prevSelectedVisit) => ({
      ...prevSelectedVisit,
      trial_type: event.target.value,
    }));
  };

  const handlePhotoTypeChange = (newValue, actionMeta) => {
    switch (actionMeta.action) {
      case "remove-value":
        if (actionMeta.removedValue.isFixed) {
          setMessage("Cannot Remove this Photo Type!");
          setTypeOfMessage("success");
          setTimeout(() => {
            document.getElementById("cusMessages").style.right = "2rem";
          }, 100);
          setTimeout(() => {
            document.getElementById("cusMessages").style.right = "-99rem";
          }, 3000);
          return newValue;
        } else {
          setHasChanged(true);
          newValue = selectedVisit?.extra_photos?.filter(
            (v) => v.value != actionMeta.removedValue.value
          );
          setSelectedVisit((photo_type) => {
            return {
              ...photo_type,
              extra_photos: newValue,
            };
          });
        }
        break;
      case "pop-value":
        break;
      case "clear":
        setHasChanged(true);
        newValue = selectedVisit?.extra_photos?.filter((v) => v.isFixed);
        setSelectedVisit((photo_type) => {
          return {
            ...photo_type,
            extra_photos: newValue,
          };
        });
        break;
      case "select-option":
        setHasChanged(true);
        setSelectedVisit((photo_type) => {
          return {
            ...photo_type,
            extra_photos: newValue,
          };
        });
        break;
    }
  };

  useEffect(() => {
    if (
      (patientId === undefined &&
        (role === "Investigator" ||
          role === "Sub_investigator" ||
          role === "Inv_coordinator")) ||
      (patientId &&
        (role === "CRO Admin" ||
          role === "Cro_coordinator" ||
          role === "Data Manager")) ||
      (patientId === undefined &&
        selectedVisit.visit_number !== 0 &&
        selectedVisit.visit_number !== -3) ||
      (selectedVisit.visit_number !== 0 &&
        selectedVisit.visit_number !== -1 &&
        selectedVisit.visit_number !== -3 &&
        (role === "Investigator" ||
          role === "Sub_investigator" ||
          role === "Inv_coordinator" ||
          role === "CRO Admin" ||
          role === "Cro_coordinator" ||
          role === "Data Manager"))
    ) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [patientId, role, selectedVisit]);

  React.useEffect(() => {
    if (
      (selectedVisit.visit_number === -1 ||
        selectedVisit.visit_number === -2 ||
        selectedVisit.visit_number === -3) &&
      patientId
    ) {
      SpecialVisitsPhotoSessions();
    }
  }, [selectedVisit.visit_number]);

  const routeTo = () => {
    navigate(`/form-creater-v2/?trial_id=${trialId}`);
  };

  useEffect(() => {
    if (selectedVisit.id !== undefined) {
      setSurveyData(selectedVisit.trial_block);

      if (prevSelectedVisitIdRef.current !== selectedVisit.id) {
        formModelObjects();
      }

      prevSelectedVisitIdRef.current = selectedVisit.id;
    }
  }, [selectedVisit]);

  const savePdf = function (includeAnswers) {
    setIsLoadingPdf(true);

    const removeVisibilityConditions = (element) => {
      if (element.type === "panel" && element.elements) {
        return {
          ...element,
          elements: element.elements.map(removeVisibilityConditions),
        };
      } else if (element.type === "matrix" && element.columns) {
        return {
          ...element,
          columns: element.columns.map((column) => ({
            ...column,
            visibleIf: undefined,
          })),
          rows: element.rows.map((row) => ({
            ...row,
            visibleIf: undefined,
          })),
          visibleIf: undefined,
        };
      } else if (
        element.type === "matrixdynamic" ||
        element.type === "matrixdropdown"
      ) {
        return {
          ...element,
          columns: element.columns.map((column) => ({
            ...column,
            visibleIf: undefined,
          })),
          visibleIf: undefined,
        };
      } else {
        return { ...element, visibleIf: undefined };
      }
    };

    const formatElementValue = (element) => {
      if (typeof element === "object" && element !== null) {
        if (element.value && element.value !== "undefined") {
          return element.value;
        } else if (element.title) {
          return element.title;
        } else if (element.name) {
          return element.name;
        } else {
          return "No value available";
        }
      }
      return String(element);
    };

    const formatElements = (element) => {
      if (element.type === "panel" && element.elements) {
        return {
          ...element,
          elements: element.elements.map(formatElements),
        };
      } else if (element.type === "matrix" && element.columns) {
        return {
          ...element,
          columns: element.columns.map((column) => ({
            ...column,
            title: formatElementValue(column),
          })),
          rows: element.rows.map((row) => ({
            ...row,
            text: formatElementValue(row),
          })),
        };
      } else if (
        element.type === "matrixdynamic" ||
        element.type === "matrixdropdown"
      ) {
        return {
          ...element,
          columns: element.columns.map((column) => ({
            ...column,
            title: formatElementValue(column),
          })),
          rows: element.rows
            ? element.rows.map((row) => ({
                ...row,
                value: formatElementValue(row),
              }))
            : [],
        };
      } else {
        return {
          ...element,
          value: formatElementValue(element),
        };
      }
    };

    let pages = [];
    let answers = {};
    surveyData?.forEach((survey) => {
      survey.surveyJson?.pages?.forEach((page) => {
        const modifiedPage = {
          ...page,
          elements: page.elements
            ?.map(removeVisibilityConditions)
            .map(formatElements),
        };
        pages.push({ ...modifiedPage, title: survey.surveyJson?.title });
      });
      if (includeAnswers) {
        answers = { ...answers, ...survey.answer_json_obj };
      }
    });

    const surveyPdf = new SurveyPDF({ pages });
    if (includeAnswers) {
      surveyPdf.data = answers;
    }

    // const surveyPdf = new SurveyPDF({ pages: pages });
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hour = String(now.getHours()).padStart(2, "0");
    const minute = String(now.getMinutes()).padStart(2, "0");
    const second = String(now.getSeconds()).padStart(2, "0");

    let visitInfo;
    if (fileName.visit_number === 0) {
      visitInfo = selectedVisit.visit_name || "Baseline";
    } else if (fileName.visit_number === -1) {
      visitInfo = selectedVisit.visit_name || "Adverse_Event";
    } else if (fileName.visit_number === -2) {
      visitInfo = selectedVisit.visit_name || "Unscheduled_Event";
    } else if (fileName.visit_number === -3) {
      visitInfo = selectedVisit.visit_name || "Screening_Visit";
    } else {
      visitInfo = selectedVisit.visit_name || `Visit_${fileName.visit_number}`;
    }

    const customFilename = `${year}${month}${day}T${hour}${minute}${second}__${fileName?.trial_name}__${visitInfo}`;

    surveyPdf.onRenderFooter.add((pageIndex, canvas) => {
      const createdAtDate = `${year}${month}${day}T${hour}${minute}${second}`;
      const visitLabel = visitInfo.replace("_", " ");
      const headerText = `Trial: ${fileName.trial_name}    Visit: ${visitLabel}    TimeStamp: ${createdAtDate}`;

      canvas.drawText({
        text: headerText,
        fontSize: 10,
        isBold: true,
      });
    });
    surveyPdf.save(customFilename);
    setIsLoadingPdf(false);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <div className="col-md-12 text-end">
          {role !== "Investigator" &&
            role !== "Inv_coordinator" &&
            role !== "Sub_investigator" && (
              <button
                onClick={() => routeTo()}
                className="btn btn-primary btn-sm mx-3 my-2"
                disabled={loaderContext.loaderCount > 0}
              >
                Create eCRF blocks
              </button>
            )}

          <button
            onClick={() => savePdf(false)}
            className="btn btn-primary btn-sm mx-3 my-3"
            disabled={isLoadingPdf || loaderContext.loaderCount > 0}
          >
            {isLoadingPdf ? (
              <span
                className="spinner-border spinner-border-sm me-2"
                role="status"
                aria-hidden="true"
              ></span>
            ) : null}
            Export as Source Doc
          </button>

          <button
            onClick={() => savePdf(true)}
            className="btn btn-primary btn-sm mx-3 my-3"
            disabled={isLoadingPdf || loaderContext.loaderCount > 0}
          >
            {isLoadingPdf ? (
              <span
                className="spinner-border spinner-border-sm me-2"
                role="status"
                aria-hidden="true"
              ></span>
            ) : null}
            Export as Filled Source Doc
          </button>

          {((role === "CRO Admin" && patientId === undefined) ||
            (role === "Cro_coordinator" && patientId === undefined) ||
            (role === "Data Manager" && patientId === undefined) ||
            role === "Investigator" ||
            (role === "Inv_coordinator" && patientId !== undefined)) && (
            <>
              {selectedBlock === "Patient Activity in this Visit" ||
              selectedBlock ? (
                <button
                  onClick={() => handleVisitUpdate()}
                  className="btn btn-primary btn-sm mx-3"
                  disabled={!hasChanged}
                  style={{ marginLeft: "85%" }}
                >
                  Update Visit Info
                </button>
              ) : (
                ""
              )}
              {role === "CRO Admin" ||
              role === "Cro_coordinator" ||
              role === "Data Manager" ||
              role === "Super Admin" ? (
                ""
              ) : (
                <h6 style={{ textAlign: "left", marginLeft: "20px" }}>
                  Visit Info
                </h6>
              )}
            </>
          )}
        </div>

        {allVisits.length > 0 && (
          <TabsWithDropdown
            numberOfVisitsArray={numberOfVisitsArray}
            allVisits={allVisits}
            patientId={patientId}
            handleTabChange={handleTabChange}
            value={value}
            selectedVisit={selectedVisit}
            setSelectedVisit={setSelectedVisit}
            setValue={setValue}
          />
        )}
      </Box>
      <>
        {loaderContext.loaderCount > 0 ? (
          <Loader />
        ) : (
          numberOfVisitsArray.map((number, index) => {
            return (
              <TabPanel value={value} index={index}>
                <VisitNameAccordion
                  role={role}
                  handleVisitFieldChange={handleVisitFieldChange}
                  selectedVisit={selectedVisit}
                />
                {patientId &&
                  selectedVisit.visit_number !== -4 &&
                  selectedVisit.visit_number !== -2 &&
                  selectedVisit.visit_number !== -1 && (
                    <ScheduleVisits
                      selectedVisit={selectedVisit}
                      trialId={trialId}
                      trial={trial}
                      patientId={patientId}
                      setScheduleBaselineModalShow={
                        setScheduleBaselineModalShow
                      }
                      AllVisitsData={AllVisitsData}
                      patientStatus={patientStatus}
                      infoData={infoData}
                      setInfoData={setInfoData}
                    />
                  )}
                {selectedVisit.visit_number !== -1 &&
                  selectedVisit.visit_number !== -2 &&
                  selectedVisit.visit_number !== -4 && (
                    <VisitScheduleAccordion
                      role={role}
                      days={days}
                      hours={hours}
                      endDays={endDays}
                      endHours={endHours}
                      number={selectedVisit.visit_number}
                      handleDaysChange={handleDaysChange}
                      handleHoursChange={handleHoursChange}
                      handleDaysChangeEnd={handleDaysChangeEnd}
                      handleHoursChangeEnd={handleHoursChangeEnd}
                      baseline_name={baseline_name}
                    />
                  )}

                {(selectedVisit.visit_number === -1 && patientId) ||
                (selectedVisit.visit_number === -2 && patientId) ? (
                  <SpecialVisitSession
                    photoSessions={photoSessions}
                    role={role}
                    patientId={patientId}
                    selectedVisit={selectedVisit}
                    handleTrialTypeChange={handleTrialTypeChange}
                    selectedBlock={selectedBlock}
                    setSelectedBlock={setSelectedBlock}
                    localBlocks={localBlocks}
                    handleSelectBlock={handleSelectBlock}
                    handleBlockChange={handleBlockChange}
                    surveyFormBool={surveyFormBool}
                    handleBoolOperation={handleBoolOperation}
                    setSurveyFormOption={setSurveyFormOption}
                    handleVisitFieldChange={handleVisitFieldChange}
                    surveyFormOption={surveyFormOption}
                    allSurveys={allSurveys}
                    setModalShow={setModalShow}
                    consentFormBool={consentFormBool}
                    setConsentFormOption={setConsentFormOption}
                    handlePhotoTypeChange={handlePhotoTypeChange}
                    trialId={trialId}
                    disable={disable}
                    consentFormOption={consentFormOption}
                    allConsents={allConsents}
                    photoTypes={photoTypes}
                    setHasChanged={setHasChanged}
                    hasChanged={hasChanged}
                    infoData={infoData}
                    setMessage={setMessage}
                    patientDetailApi={patientDetailApi}
                    setSelectedVisit={setSelectedVisit}
                    setTypeOfMessage={setTypeOfMessage}
                    AllVisitsData={AllVisitsData}
                    modalVisitChangeShow={modalVisitChangeShow}
                    setModalVisitChangeShow={setModalVisitChangeShow}
                    selectedSessionId={selectedSessionId}
                    setSelectedSessionId={setSelectedSessionId}
                    searchTrailType={searchTrailType}
                    setSearchTrailType={setSearchTrailType}
                    searchBlockName={searchBlockName}
                    setSearchBlockName={setSearchBlockName}
                    newForm={newForm}
                    setNewForm={setNewForm}
                    SpecialVisitsPhotoSessions={SpecialVisitsPhotoSessions}
                  />
                ) : (
                  <AllBlocksAccordion
                    role={role}
                    patientId={patientId}
                    selectedVisit={selectedVisit}
                    handleTrialTypeChange={handleTrialTypeChange}
                    selectedBlock={selectedBlock}
                    setSelectedBlock={setSelectedBlock}
                    localBlocks={localBlocks}
                    handleSelectBlock={handleSelectBlock}
                    handleBlockChange={handleBlockChange}
                    surveyFormBool={surveyFormBool}
                    handleBoolOperation={handleBoolOperation}
                    setSurveyFormOption={setSurveyFormOption}
                    handleVisitFieldChange={handleVisitFieldChange}
                    surveyFormOption={surveyFormOption}
                    allSurveys={allSurveys}
                    setModalShow={setModalShow}
                    consentFormBool={consentFormBool}
                    setConsentFormOption={setConsentFormOption}
                    handleVisitUpdate={handleVisitUpdate}
                    handlePhotoTypeChange={handlePhotoTypeChange}
                    handleInstruction={handleInstruction}
                    trialId={trialId}
                    disable={disable}
                    consentFormOption={consentFormOption}
                    allConsents={allConsents}
                    photoTypes={photoTypes}
                    setHasChanged={setHasChanged}
                    hasChanged={hasChanged}
                    infoData={infoData}
                    setMessage={setMessage}
                    patientDetailApi={patientDetailApi}
                    setSelectedVisit={setSelectedVisit}
                    setTypeOfMessage={setTypeOfMessage}
                    AllVisitsData={AllVisitsData}
                    modalVisitChangeShow={modalVisitChangeShow}
                    setModalVisitChangeShow={setModalVisitChangeShow}
                    selectedSessionId={selectedSessionId}
                    setSelectedSessionId={setSelectedSessionId}
                    setAddNewSurvey={setAddNewSurvey}
                    newForm={newForm}
                    setNewForm={setNewForm}
                    searchTrailType={searchTrailType}
                    setSearchTrailType={setSearchTrailType}
                    searchBlockName={searchBlockName}
                    setSearchBlockName={setSearchBlockName}
                    trialDetailApi={trialDetailApi}
                    trial={trial}
                  />
                )}
              </TabPanel>
            );
          })
        )}
      </>
    </Box>
  );
}
