import React from 'react';
import {
  GridToolbarColumnsButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid-pro';
import Button from '@mui/material/Button';

import * as XLSX from 'xlsx';
import moment from 'moment';

const CustomToolbar = ({
  reportName = 'Report',
  rows = [],
  columns = [],
  columnVisibilityModel = {},
}) => {
  const excludedFields = ['thumbnail']; //field names to exclude

  const exportToXlsx = () => {
    try {
      // Filter visible columns based on columnVisibilityModel and exclude specific fields
      const visibleColumns = columns.filter(
        (col) =>
          columnVisibilityModel[col.field] !== false &&
          !excludedFields.includes(col.field)
      );

      // Map rows to export only visible and allowed columns
      const dataToExport = rows.map((row) =>
        visibleColumns.reduce((acc, col) => {
          acc[col.headerName || col.field] =
            typeof row[col.field] === 'object' ? '' : row[col.field]; // Handle non-text data

          if (col.field === 'image_name' && row[col.field]) {
            const value = row[col.field];
            const parts = value.split("__");
            const timestamp = parts[0];
            if (timestamp) {
              const formattedTimestamp = `${timestamp.slice(0, 4)}-${timestamp.slice(4, 6)}-${timestamp.slice(6, 8)}T${timestamp.slice(9, 11)}:${timestamp.slice(11, 13)}:${timestamp.slice(13, 15)}`;
              const formattedDate = moment
                .utc(formattedTimestamp, "YYYY-MM-DDTHH:mm:ss")
                .local()
                .format("YYYYMMDDTHHmmss");
              parts[0] = formattedDate;
              const newImageName = parts.join("__");
              acc[col.headerName || col.field] = newImageName;
            }
          }

          return acc;
        }, {})
      );

      // Create XLSX file
      const worksheet = XLSX.utils.json_to_sheet(dataToExport);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Report');
      const currentTime = moment().format('YYYYMMDDTHHmmss');
      const reportNameWithoutTimestamp = reportName.replace(/^\d{8}T\d{6}_/, '');
      const filename = `${currentTime}___${reportNameWithoutTimestamp}.xlsx`;
      XLSX.writeFile(workbook, filename);
    } catch (error) {
      console.error('Failed to export XLSX:', error);
    }
  };

  return (
    <div>
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
      {/* Custom XLSX Export Button */}
      <Button
        variant="text"
        color="primary"
        size="small"
        style={{
          marginLeft: 'auto',
          padding: '4px 8px',
          fontSize: '0.8rem',
          textTransform: 'none',
        }}
        onClick={exportToXlsx}
      >
        Export to XLSX
      </Button>
    </div>
  );
};
export default CustomToolbar;
