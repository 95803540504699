export const isAgeValid = (dobString, lowerAge, upperAge) => {
    const dob = new Date(dobString);
  
    if (isNaN(dob)) {
      return "Invalid date format. Please provide a valid date.";
    }
  
    const today = new Date();
  
    let age = today.getFullYear() - dob.getFullYear();
    const monthDifference = today.getMonth() - dob.getMonth();
    const dayDifference = today.getDate() - dob.getDate();
  
    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
      age--;
    }
  
    if (age < lowerAge || age >= upperAge) {
      return false;
    } else {
      return true;
    }
  };
  