import React, { useEffect, useState } from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { useRef } from 'react';
import { __API_URL__ } from '../../SERVER_URL';
import axios from 'axios';
import Avatar from '../../assets/img/avatar.png';
import CustomToolbar from './CustomToolbar';
import moment from 'moment/moment';

const CHARACTER_LIMITS = {
  'SiteID-ParticipantID': 10,
  name: 54,
  visit_name: 54,
  visit_status: 12,
  photos_taken: 1,
  photo_type: 54,
  image_name: 150,
  created_at: 9,
};

const truncateText = (text, limit) => {
  if (text && text.length > limit) {
    return `${text.substring(0, limit)}...`;
  }
  return text;
};

const generateColumn = (field, headerName, width, showVisitName) => {
  const columnConfig = {
    sortable: false,
    editable: false,
    filterable: false,
    field: field,
    headerName: headerName,
    width: width,
  };

  if (CHARACTER_LIMITS[field]) {
    columnConfig.valueFormatter = (params) => {
      return truncateText(params.value, CHARACTER_LIMITS[field]);
    };
  }

  if (field === 'thumbnail') {
    columnConfig.renderCell = (params) => {
      const imageUrl =
        params.row.thumbnail?.length > 0
          ? `${__API_URL__ + params.row.thumbnail}`
          : Avatar;
      return (
        <div>
          <img
            src={imageUrl}
            alt="img"
            style={{
              width: '40px',
              height: '46px',
              borderRadius: '50%',
              objectFit: 'cover',
            }}
          />
        </div>
      );
    };
  }
  if (field === 'image_name') {
    columnConfig.valueFormatter = (params) => {
      const value = params.value;
      if (value) {
        const parts = value.split("__");
        const timestamp = parts[0];
        if (timestamp) {
          const formattedTimestamp = `${timestamp.slice(0, 4)}-${timestamp.slice(4, 6)}-${timestamp.slice(6, 8)}T${timestamp.slice(9, 11)}:${timestamp.slice(11, 13)}:${timestamp.slice(13, 15)}`;
          const formattedDate = moment.utc(formattedTimestamp, "YYYY-MM-DDTHH:mm:ss").local().format("YYYYMMDDTHHmmss");
          parts[0] = formattedDate;
          const newImageName = parts.join("__");
          return newImageName;
        }
      }
      return value;
    };
  }
  if (field === 'visit_number' && showVisitName) {
    columnConfig.headerName = 'Visit';
    columnConfig.renderCell = (params) => {
      const visitDisplay = params.row.visit_name
        ? params.row.visit_name
        : params.row.visit_number;
      return <div>{visitDisplay}</div>;
    };
  }
  return columnConfig;
};

const handleRowClick = (navigate) => (params) => {
  window.open(`/patient/${params.row?.subject_id}/info`).focus();
};

const ReportColumns = (props) => {
  const reportData = props.reportData;
  const setReportData = props.setReportData;
  const TOKEN = localStorage.getItem('token');
  const reportTableConfigurations = props.reportTableConfigurations;
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: reportTableConfigurations.noOfRows,
  });
  const setData = props.setData;
  const IsLoading = props.IsLoading;
  const setIsLoading = props.setIsLoading;
  const trialDetails = props.trialDetails;
  const reportName = props.reportName;
  const role = props.role;
  const [columnDataState, setColumnDataState] = useState([]);
  const navigate = useNavigate();
  const [search, setSearch] = useState({});
  const initialRenderForPageNumber = useRef(true);
  const initialRenderForSearch = useRef(true);
  const isScoresReportSelected = trialDetails.selected_report === 'SCORES';
  const [downloadDisable, setDownloadDisable] = useState(false);
  const [IsLoadingPhoto, setIsLoadingPhoto] = useState(false);
  const [IsLoadingVideo, setIsLoadingVideo] = useState(false);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});

  const columnData = [
    ['cro_admin_email', 'CRO Admin Email', 300],
    ['id', 'Trial Id', 300],
    ['trial_custom_id', 'CRO Assigned Trial Id', 300],
    ['name', 'Trial Name', 300],
    ['site_id', 'Site Id', 300],
    ['participant_number', 'Participant Id', 300],
    ['investigator_email', 'Investigator Emails', 300],
    ['visit_number', 'Visit', 300, true],
    ['visit_open_date', 'Visit Open Date', 300],
    ['drug_id', 'Drug Id', 300],
    ['visit_status', 'Visit Status', 300],
  ];
  if (role === 'Super Admin') {
    columnData.push(['subject_id', 'Internal_ID', 300]);
  }
  if (
    role !== 'CRO Admin' &&
    role !== 'Cro_coordinator' &&
    role !== 'Data Manager'
  ) {
    columnData.push(
      ['first_name', 'Subject First Name', 300],
      ['last_name', 'Subject Last Name', 300]
    );
  }
  if (trialDetails?.selected_report === 'POEM REPORT') {
    columnData.push(
      ['question', 'Question', 400],
      ['answer', 'Answer', 300],
      ['extra_text', 'Extra Text', 400]
    );
  }
  if (trialDetails?.selected_report !== 'POEM REPORT') {
    columnData.push(
      ['cro_admin', 'CRO Admin ID', 300],
      ['session_start_date', 'Visit Start date', 300],
      ['activity_type', 'Activity Type', 300],
      ['activity_completed', 'Activity Completed', 300]
    );
  }
  if (trialDetails?.selected_report === 'SCORES') {
    columnData.splice(
      columnData.findIndex((col) => col[0] === 'session_start_date'),
      1
    );
    columnData.splice(
      columnData.findIndex((col) => col[0] === 'visit_open_date'),
      1
    );
    columnData.push(
      ['tle_score', 'TLE Score', 300],
      ['tls_score', 'TLS Score', 300],
      ['tli_score', 'TLI Score', 300]
    );
  }
  if (trialDetails?.selected_report === 'ECRF') {
    columnData.length = 0;
    if (role === 'Super Admin') {
      columnData.push(['subject_id', 'Internal_ID', 300]);
    }
    columnData.push(
      ['name', 'Trial Name', 300],
      ['visit_status', 'Visit Status', 300],
      ['site_id', 'Site Id', 300],
      ['participant_number', 'Participant Id', 300],
      ['visit_number', 'Visit', 300, true],
      ['drug_id', 'Drug Id', 300],
      ['block_name', 'Block Name', 300],
      ['key_value', 'Value', 600]
    );
  }
  if (trialDetails?.selected_report === 'PHOTO REPORT') {
    columnData.length = 0;
    if (role === 'Super Admin') {
      columnData.push(['subject_id', 'Internal_ID', 300]);
    }
    columnData.push(
      ['SiteID-ParticipantID', 'SiteID-SubjectID', 300],
      ['name', 'Trial Name', 300],
      ['visit_name', 'Visit Name', 300],
      ['visit_status', 'Visit Status', 300],
      ['photos_taken', 'Photo Taken', 300],
      ['photo_type', 'Photo Type', 300],
      ['image_name', 'Photo File Name', 300],
      ['created_at', 'Captured At', 300],
      ['thumbnail', 'Image', 300]
    );
  }
  if (trialDetails?.selected_report === 'VIDEO REPORT') {
    columnData.length = 0;
    if (role === 'Super Admin') {
      columnData.push(['subject_id', 'Internal_ID', 300]);
    }
    columnData.push(
      ['name', 'Trial Name', 300],
      ['participant_number', 'Participant Id', 300],
      ['visit_number', 'Visit', 300, true],
      ['site_id', 'Site Id', 300],
      ['drug_id', 'Drug Id', 300],
      ['created_at', 'Created at Date', 300]
    );
  }
  if (trialDetails?.selected_report === 'VISIT REPORT') {
    columnData.length = 0;
    columnData.push(
      ['name', 'Trial Name', 300],
      ['site_id', 'Site', 300],
      ['formatted_intials', 'Patient', 300],
      ['visit_name', 'Visit', 300],
      ['visit_start_date', 'Start Date', 300],
      ['clinical_trial_status', 'Status', 300]
    );
  }
  if (trialDetails?.selected_report === 'ADVERSE EVENT REPORT') {
    columnData.length = 0;
    columnData.push(
      ['name', 'Trial Name', 300],
      ['participant_number', 'Participant Id', 300],
      ['visit_name', 'Visit', 300],
      ['block_name', 'Block Name', 300],
      ['key_value', 'Value', 600]
    );
  }
  if (trialDetails?.selected_report === 'UNSCHEDULED EVENT REPORT') {
    columnData.length = 0;
    columnData.push(
      ['name', 'Trial Name', 300],
      ['participant_number', 'Participant Id', 300],
      ['visit_name', 'Visit', 300],
      ['block_name', 'Block Name', 300],
      ['key_value', 'Value', 600]
    );
  }
  useEffect(() => {
    if (props) {
      const columns = [];
      for (let i = 0; i < columnData?.length; i++) {
        const column = generateColumn(
          columnData[i][0],
          columnData[i][1],
          columnData[i][2],
          columnData[i][3]
        );
        columns.push(column);
      }
      setColumnDataState(columns);
    }
  }, [props]);

  useEffect(() => {
    if (!initialRenderForSearch.current) {
      setIsLoading(true);
      if (search.page && trialDetails.selected_report) {
        const params = `?page=${search?.page}`;
        let url;
        switch (trialDetails.selected_report) {
          case 'GENERAL REPORT':
            url = `api/photos/download_data/${params}`;
            break;
          case 'POEM REPORT':
            url = `api/photos/download_poem_data/${params}`;
            break;
          case 'ECRF':
            url = `api/ecrf/download_ecrf_data/${params}`;
            break;
          case 'PHOTO REPORT':
            url = `api/photos/download_photo_data/${params}`;
            break;
          case 'VIDEO REPORT':
            url = `api/photos/download_photo_data/${params}`;
            break;
          case 'VISIT REPORT':
            url = `api/photos/download_visit_report/${params}`;
            break;
        }
        axios
          .post(`${__API_URL__}/${url}`, trialDetails, {
            headers: {
              Authorization: `Token ${TOKEN}`,
            },
          })
          .then((res) => {
            setData(res.data);
            setReportData(res.data.results);
            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
          });
      }
    } else initialRenderForSearch.current = false;
  }, [search]);

  useEffect(() => {
    if (!initialRenderForPageNumber.current) {
      setSearch({
        ...search,
        page: paginationModel.page + 1,
      });
    } else initialRenderForPageNumber.current = false;
  }, [paginationModel.page]);

  const dataGridConfig = {
    getRowId: (row) => row.row,
    columns: columnDataState,
    rows: reportData,
    components: {
      Toolbar: (props) => (
        <>
          <CustomToolbar
            reportName={reportName}
            rows={reportData}
            columns={columnDataState}
            columnVisibilityModel={columnVisibilityModel}
            {...props}
          />
        </>
      ),
    },
    onRowClick: handleRowClick(navigate),
    onColumnResize: () => props.setFilterConfigurationLastStateSaved(false),
    filterMode: 'server',
    slotProps: {
      columnsPanel: {
        disableHideAllButton: false,
        disableShowAllButton: false,
      },
    },
    checkboxSelection: true,
    disableSelectionOnClick: true,
    autoHeight: true,
    loading: IsLoading,
    onColumnVisibilityModelChange: (newModel) =>
      setColumnVisibilityModel(newModel),
  };

  if (isScoresReportSelected) {
    delete dataGridConfig.pagination;
  } else {
    dataGridConfig.pagination = true;
    dataGridConfig.paginationModel = paginationModel;
    dataGridConfig.paginationMode = 'server';
    dataGridConfig.rowsPerPageOptions = [300];
    dataGridConfig.rowCount = props.data.count;
    dataGridConfig.onPaginationModelChange = setPaginationModel;
    dataGridConfig.pageSizeOptions = [300];
  }
  dataGridConfig.getRowHeight = () => 'auto';

  const downloadThumbnailsAsZip = async () => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const currentTime = new Date().toLocaleString('en-US', { 
      timeZone: timezone, 
      hour12: false, 
      year: 'numeric', 
      month: '2-digit', 
      day: '2-digit', 
      hour: '2-digit', 
      minute: '2-digit', 
      second: '2-digit'
    });
    const [month, day, year, hour, minute, second] = currentTime.split(/[\s,\/:-]+/);
    const formattedTime = `${year}${month}${day}T${hour}${minute}${second}`;
    setIsLoadingPhoto(true);
    const updatedObj = {
      ...trialDetails,
      is_zip: true,
      timezone: timezone,
      formattedTime: formattedTime
    };
    try {
      const response = await axios.post(
        `${__API_URL__}/api/photos/download_photo_data/`,
        updatedObj,
        {
          headers: {
            Authorization: `Token ${TOKEN}`,
          },
        }
      );
      setDownloadDisable(true);
      const downloadUrl = response.data['download_link'];
      setFileName(response.data['file_name']);
      setIsLoadingPhoto(false);
      setLink(downloadUrl);
    } catch (error) {
      console.error('Error downloading the zip file:', error);
    }
  };
  const downloadVideoAsZip = async () => {
    setIsLoadingVideo(true);
    const updatedObj = {
      ...trialDetails,
      is_zip: true,
    };
    try {
      const response = await axios.post(
        `${__API_URL__}/api/photos/download_video_data/`,
        updatedObj,
        {
          headers: {
            Authorization: `Token ${TOKEN}`,
          },
        }
      );
      setDownloadDisable(true);
      const downloadUrl = response.data['download_link'];
      setFileName(response.data['file_name']);
      setIsLoadingVideo(false);
      setLink(downloadUrl);
    } catch (error) {
      console.error('Error downloading the zip file:', error);
    }
  };

  const [link, setLink] = useState('');
  const [fileName, setFileName] = useState('');
  const anchorRef = useRef();

  useEffect(() => {
    if (link && fileName) {
      anchorRef.current.click();
      setTimeout(() => {
        axios
          .post(
            `${__API_URL__}/api/photos/delete_file`,
            { file_name: fileName },
            {
              headers: {
                Authorization: `Token ${TOKEN}`,
              },
            }
          )
          .then((res) => {
            console.log('SUCCESS');
          })
          .catch((err) => {
            console.log('ERROR');
          })
          .finally(() => {
            setDownloadDisable(false);
            setLink('');
            setFileName('');
          });
      }, 5000);
    }
  }, [link, fileName]);

  const handleCSVButtonClick = (field) => {
    if (trialDetails?.trials?.length <= 0 && field != 'trials') {
      props.toast.error('Please select Trial Name first.');
      return;
    }
    setIsLoadingPhoto(true);
    setIsLoadingPhoto(false);
    const exportButton = dataGridRef.current
      .querySelectorAll('div')[0]
      .querySelectorAll('button')[2]
      .click();
    setTimeout(() => {
      document
        .querySelectorAll('[role="tooltip"]')[0]
        .firstChild.querySelectorAll('[role="menu"]')[0]
        .firstChild.click();
    });
  };
  const dataGridRef = useRef(null);
  return (
    <div
      className="card form-control"
      style={{ width: '100%', height: '80%', marginLeft: '10%' }}
    >
      <a
        href={link}
        id="customLink"
        style={{ display: 'none' }}
        target="_blank"
        ref={anchorRef}
      ></a>
      <Box sx={{ height: 800, width: '80%' }}>
        {trialDetails?.selected_report === 'GENERAL REPORT' ||
        trialDetails?.selected_report === 'POEM REPORT' ||
        trialDetails?.selected_report === 'SCORES' ? (
          <>
            <div className="col-md-9">
              <button
                type="button"
                className={`btn btn-primary btn-sm m-4${
                  IsLoadingPhoto ? ' btn-loading' : ''
                }`}
                onClick={handleCSVButtonClick}
                disabled={props.IsDisabledDownload}
              >
                {IsLoadingPhoto ? (
                  <>
                    <i className="fa fa-spinner fa-spin"></i> Loading
                  </>
                ) : (
                  'Download REPORT'
                )}
              </button>
            </div>
          </>
        ) : (
          ''
        )}
        {trialDetails?.selected_report === 'PHOTO REPORT' ? (
          <>
            <button
              type="button"
              className={`btn btn-primary btn-sm m-4${
                IsLoadingPhoto ? ' btn-loading' : ''
              }`}
              onClick={downloadThumbnailsAsZip}
              disabled={downloadDisable || props.IsDisabledDownload}
            >
              {IsLoadingPhoto ? (
                <>
                  <i class="fa fa-spinner fa-spin"></i> Loading
                </>
              ) : (
                'Download Images as ZIP'
              )}
            </button>
          </>
        ) : trialDetails?.selected_report === 'VIDEO REPORT' ? (
          <>
            <button
              type="button"
              className={`btn btn-primary btn-sm m-4${
                IsLoadingVideo ? ' btn-loading' : ''
              }`}
              onClick={downloadVideoAsZip}
              disabled={downloadDisable || props.IsDisabledDownload}
            >
              {IsLoadingVideo ? (
                <>
                  <i class="fa fa-spinner fa-spin"></i> Loading
                </>
              ) : (
                'Download Videos as ZIP'
              )}
            </button>
          </>
        ) : (
          ''
        )}
        <div>
          <DataGridPro
            {...dataGridConfig}
            ref={dataGridRef}
            localeText={{
              toolbarExport: 'Export this View',
            }}
          />
        </div>
      </Box>
    </div>
  );
};

export default ReportColumns;
